// Variables
$livvic: "Livvic", sans-serif;
$dancing: "Dancing Script", cursive;
$shadows: "Shadows Into Light", cursive;

$green: #306b34;
$offwhite: #eff1ed;
$crimson: #721817;
$lightblue: #416165;
$darkblue: #0b3948;

.tripList-wrapper {
  margin: auto;
  width: 100%;
}

.list-explanation {
  margin: 20px auto;
  width: 60%;
  font-family: $livvic;
  font-size: 16px;
  text-align: center;
}

.tripList-header {
  height: 50px;
  background: $lightblue;
  color: $offwhite;
  font-family: $livvic;
  font-size: 28px;
  text-align: center;
  padding: 15px 5px;
}

.signature {
  width: 70%;
  font-family: $dancing;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
}